<template>
  <v-container>
    <v-sheet v-show="!($store.state.cn22s.count > 0)" :color="`${$vuetify.theme.dark ? '' : 'grey lighten-4'}`"
             class="px-3 pt-3 pb-12">
      <v-responsive class="mx-auto">
        <v-skeleton-loader ref="skeleton" type="table" class="mx-auto"></v-skeleton-loader>
      </v-responsive>
    </v-sheet>
    <v-data-table
      v-show="$store.state.cn22s.count > 0"
      :headers="headers"
      :items-per-page="10"
      :items="$store.state.cn22s.results"
      class="elevation-0"
      :loading="loading"
      :options.sync="options"
      :server-items-length="$store.state.cn22s.count"
      loading-text="Fetching CN22's..."
      no-data-text="No available parcels to display!"
      no-results-text="No available parcels to display!"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      multi-sort
      calculate-widths
      height="800"
      fixed-header
      :footer-props="{showFirstLastPage: true, itemsPerPageOptions: [10, 25, 50, 100, 250]}"
      :search="search"
    >
      <template v-slot:body="{items}">
        <tbody>
        <tr v-for="item in items" :key="item.id" @click="select(item)"
            :class="`${$store.state.selectedData && item.id === $store.state.selectedData.value.id ? $vuetify.theme.dark ? 'grey darken-3 ': 'grey lighten-2' : ''}`">
          <td class="text-right">
            <span class="caption">{{ item.scan_time ? item.scan_time.toFixed(5): 'n/a' }}</span>
          </td>
          <td>
            <v-chip class="teal" dark>
              {{ item.created_by }}
            </v-chip>
          </td>
          <td>{{ format(item.date_created) }}</td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import _debounce from 'lodash/debounce'
import {format} from 'timeago.js'

export default {
  data() {
    return {
      page: 1,
      headers: [
        {text: 'SCAN TIME (s)', value: 'scan_time', align: 'end'},
        {text: 'DEVICE', value: 'created_by'},
        {text: 'DATE CREATED', value: 'date_created'},
      ],
      loading: true,
      options: {},
      sortBy: ['date_created'],
      sortDesc: [true],
      parcels: {
        count: 0,
        results: []
      },
      initialLoad: true,
    }
  },
  computed: {
    search: {
      get() {
        return this.$store.state.search
      },
      set(val) {
        this.$store.commit('setState', ['search', val])
      }
    }
  },
  watch: {
    options: {
      handler: _debounce(function () {
        this.optionSearchHandler()
      }, 750),
      deep: true
    },
    search: {
      handler: _debounce(function () {
        this.optionSearchHandler()
      }, 500),
      deep: true
    }
  },
  methods: {
    async optionSearchHandler() {
      this.loading = true

      let params = new URLSearchParams()
      params.append('search', this.search)

      let sortBy = []
      this.options.sortBy.forEach((val, index) => {
        sortBy.push(`${this.options.sortDesc[index] ? '-' : ''}${val}`)
      })
      params.append('ordering', sortBy.join(','))
      params.append('limit', this.options.itemsPerPage)
      params.append('offset', `${(this.options.page - 1) * this.options.itemsPerPage}`)

      const results = await this.$server.get(this.$store.state.manifestServerAddress + `/v1/cn22/`, {searchParams: params}).json()
        .catch(error => {
          console.error('Error on retrieving CN22s.', error)
        })
      this.$store.commit('setState', ['cn22s', results])
      this.loading = false
    },
    select(item) {
      let data = {
        value: item,
        type: this.type
      }

      if (this.$store.state.selectedData !== data) {
        this.$store.commit('setSelectedData', {value: item, type: 'manifest'})
      }
    },
    format
  },
  beforeMount() {

    // Show app bar and left nav bar
    this.$store.commit('setState', ['leftNav', false])
    this.$store.commit('setState', ['appBar', true])
    this.$store.commit('initStore')

  }
}
</script>

<style scoped>

</style>
